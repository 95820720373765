/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import AspCartBtnBox from "../../src/component/parts/aspCartBtnBox";
import HeadlineH2 from "../../src/component/parts/headlineH2";
import HeadlineH1 from "../../src/component/parts/headlineH1";
import HeadlineH4 from "../../src/component/parts/headlineH4";
import MovieInfoTable from "../../src/component/parts/movieInfoTable";
import PerformerInfoTable from "../../src/component/parts/performerInfoTable";
import MovieLeadText from "../../src/component/parts/movieLeadText";
import ReviewText from "../../src/component/parts/reviewText";
import FiveStarRating from "../../src/component/parts/fiveStarRating";
import Goodpoints from "../../src/component/parts/goodpoints";
import Badpoints from "../../src/component/parts/badpoints";
import PrefaceText from "../../src/component/parts/prefacetext";
import {Carousel} from "react-bootstrap";
function _createMdxContent(props) {
  const _components = Object.assign({
    hr: "hr",
    h1: "h1",
    img: "img",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  if (!Carousel) _missingMdxReference("Carousel", false);
  if (!Carousel.Item) _missingMdxReference("Carousel.Item", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.hr), "\n", React.createElement(_components.h1, null, "【2022年最新】フェラチオ大好き熟女モノAV情報まとめ"), "\n", React.createElement(_components.hr), "\n", React.createElement(PrefaceText, {
    preface: "今回の記事では、熟女な女優さんが熱心にフェラチオするAV動画作品についてのレビューをお届けします。 サイト管理人のレビューや、出演されている女優さんの詳しい情報などをまとめて記事にして紹介しています。アダルト動画の魅力や見どころ、出演されている女優さんの魅力、マニアも唸らせるフェチズムを満たす映像演出などについて、詳しく紹介していきます。"
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(HeadlineH2, {
    h2: "シリーズ フェラチオばかりの動画集"
  }), "\n", React.createElement(HeadlineH4, {
    h4: "唾液を垂らしてしゃぶる姿がエロすぎる！！フェラチオばかりの動画集 美熟女編41人"
  }), "\n", React.createElement(_components.img, {
    src: "1.jpg",
    alt: "唾液を垂らしてしゃぶる姿がエロすぎる！！フェラチオばかりの動画集 美熟女編41人"
  }), "\n", React.createElement(MovieInfoTable, {
    title: "唾液を垂らしてしゃぶる姿がエロすぎる！！フェラチオばかりの動画集 美熟女編41人",
    actress: "とみの伊織 真木今日子 神波多一花 塚田詩織 鈴木真夕 新垣智江 鷹宮ゆい 紗々原ゆり 高杉麻里 藤森里穂 三原ほのか 北川礼子 羽生アリサ（羽生ありさ） 伊東真緒 阿部乃みく 伊織涼子 麻里梨夏 島津かおる 南まゆ 赤瀬尚子 ERIKA（モカ、MOKA） 羽田つばさ 松島香織 白鳥寿美礼 富田優衣 翔田千里 工藤まなみ 通野未帆 葵百合香 春菜はな 本庄瞳 横山紗江子 鈴木さとみ 並木塔子 片瀬仁美 滝本エレナ 優月まりな 笹川蓉子 緒方泰子 風間ゆみ 八木あずさ",
    release: "2020/09/01",
    time: "241",
    series: "唾液を垂らしてしゃぶる姿がエロすぎる！！",
    manufacturer: "プラネットプラス",
    lavel: "七狗留"
  }), "\n", React.createElement(MovieLeadText, {
    readText: "前戯でのフェラではなくフィニッシュまで舐め上げる愛情たっぷりのフェラを収録。ジュポジュポといやらしい音を立てながら潤んだ瞳での上目遣い。そして次第に激しさを増しユサユサと揺れる巨乳。滴るヨダレにガマン汁も交わり興奮はMAXに！美熟女たち41人を厳選！どうぞお楽しみ下さい！"
  }), "\n", React.createElement(ReviewText, {
    reviewText: "一心不乱に首を振りながらジュッポジュッポと激しい音を立てるバキュームを利かせたフェラチオシーンは必見です。登場する女性陣は30代～の美しい女性ばかりなのでかなりの見ごたえがあります。前戯プレイばかりで本番シーンが無いのは少し物足りない気がしないこともないですが、かなり過激なフェラシーンを集めた作品になっていますので、満足感があります。まどろっこしい寸劇みたいなのは一切ありません。ただ次々と登場する美女が一心不乱に過激なフェラチオシーンに没頭するという作品です。4時間も動画時間があるので、時間がある休日に見るか、ちょっとづつ楽しむことになると思います。作品のコンセプトは見る人を選ぶ感じがしますが、女性陣がかなり綺麗所の方々を集めていることと、めちゃくちゃエロいのでかなりお勧めできます。エロいです。"
  }), "\n", React.createElement(Goodpoints, {
    goodpoints: React.createElement(_components.ul, null, React.createElement(_components.li, null, "作品収録時間4時間の長編大ボリューム"), React.createElement(_components.li, null, "女優さんたちが綺麗な人ばかり"), React.createElement(_components.li, null, "めちゃくちゃエロい"), React.createElement(_components.li, null, "ジュッポジュッポ"), React.createElement(_components.li, null, "男優の主張が少なく女優さんに集中できる"))
  }), "\n", React.createElement(Badpoints, {
    badpoints: React.createElement(_components.ul, null, React.createElement(_components.li, null, "本番シーンが無い"), React.createElement(_components.li, null, "単調で繰り返しが多いシーン"), React.createElement(_components.li, null, "作品すべてを観きるのに時間がかかる"), React.createElement(_components.li, null, "熟女中心ではあるが、熟女とは呼べない若い人がたまに出演する"))
  }), "\n", React.createElement(FiveStarRating, {
    star: 4.5
  }), "\n", React.createElement("div", {
    className: "prefaceBox text-center"
  }, React.createElement("div", {
    className: "prefaceBox-title"
  }, "作品ギャラリー"), React.createElement(Carousel, {
    variant: "dark"
  }, React.createElement(Carousel.Item, null, React.createElement(_components.img, {
    src: "../../images/post/1-blowjob-loves/1/04.jpg",
    alt: "唾液を垂らしてしゃぶる姿がエロすぎる！！フェラチオばかりの動画集 美熟女編41人VOL.02"
  })), React.createElement(Carousel.Item, null, React.createElement(_components.img, {
    src: "../../images/post/1-blowjob-loves/1/06.jpg",
    alt: "唾液を垂らしてしゃぶる姿がエロすぎる！！フェラチオばかりの動画集 美熟女編41人VOL.02"
  })), React.createElement(Carousel.Item, null, React.createElement(_components.img, {
    src: "../../images/post/1-blowjob-loves/1/08.jpg",
    alt: "唾液を垂らしてしゃぶる姿がエロすぎる！！フェラチオばかりの動画集 美熟女編41人VOL.02"
  })), React.createElement(Carousel.Item, null, React.createElement(_components.img, {
    src: "../../images/post/1-blowjob-loves/1/10.jpg",
    alt: "唾液を垂らしてしゃぶる姿がエロすぎる！！フェラチオばかりの動画集 美熟女編41人VOL.02"
  }))), React.createElement("small", null, "左右の矢印のボタンをクリックすると画像がスライドします")), "\n", React.createElement(AspCartBtnBox, {
    fanzaUrl: "https://al.dmm.co.jp/?lurl=https%3A%2F%2Fwww.dmm.co.jp%2Fdigital%2Fvideoa%2F-%2Fdetail%2F%3D%2Fcid%3Dh_237nacx00061%2F&af_id=daisukipress-003&ch=toolbar&ch_id=text",
    fanza: "yes",
    duga: "yes",
    dugaPath: "ppv/planetplus-1160/",
    sokumil: "yes",
    sokumilUrl: "https://www.sokmil.com/av/_item/item344202.htm?ref=search"
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(HeadlineH4, {
    h4: "唾液を垂らしてしゃぶる姿がエロすぎる！！フェラチオばかりの動画集 美熟女編27人VOL.02"
  }), "\n", React.createElement(_components.img, {
    src: "../../images/post/1-blowjob-loves/2.jpg",
    alt: "唾液を垂らしてしゃぶる姿がエロすぎる！！フェラチオばかりの動画集 美熟女編41人VOL.02"
  }), "\n", React.createElement(MovieInfoTable, {
    title: "唾液を垂らしてしゃぶる姿がエロすぎる！！フェラチオばかりの動画集 美熟女編27人VOL.02",
    actress: "\t時田こずえ 遥あやね 小野さち子 舞原聖 紗々原ゆり 北川舞 桐山結羽 純岡美乃理 松坂美紀 羽咲美亜 水川スミレ 夏希まろん 汐河佳奈 牧村彩香 泉ゆうめ 瀬名ひかり 通野未帆 黒川すみれ 本郷まや 加藤ツバキ（夏樹カオル） 鐘梨ほしな 竹内夏希 飯山香織 澤村レイコ（高坂保奈美、高坂ますみ） 菅野真穂 宮村ななこ",
    release: "\t2022/07/20",
    time: "241",
    series: "唾液を垂らしてしゃぶる姿がエロすぎる！！",
    manufacturer: "プラネットプラス",
    lavel: "七狗留"
  }), "\n", React.createElement(MovieLeadText, {
    readText: "前戯でのフェラではなくフィニッシュまで舐め上げる愛情たっぷりのフェラを収録。ジュポジュポといやらしい音を立てながら潤んだ瞳での上目遣い。そして次第に激しさを増しユサユサと揺れる巨乳。滴るヨダレにガマン汁も交わり興奮はMAXに！美熟女たち27人を厳選！どうぞお楽しみ下さい！"
  }), "\n", React.createElement("div", {
    className: "prefaceBox text-center"
  }, React.createElement("div", {
    className: "prefaceBox-title"
  }, "作品ギャラリー"), React.createElement(Carousel, {
    variant: "dark"
  }, React.createElement(Carousel.Item, null, React.createElement(_components.img, {
    src: "../../images/post/1-blowjob-loves/2/h_237nacx00104jp-11.jpg",
    alt: "唾液を垂らしてしゃぶる姿がエロすぎる！！フェラチオばかりの動画集 美熟女編41人VOL.02"
  })), React.createElement(Carousel.Item, null, React.createElement(_components.img, {
    src: "../../images/post/1-blowjob-loves/2/h_237nacx00104jp-13.jpg",
    alt: "唾液を垂らしてしゃぶる姿がエロすぎる！！フェラチオばかりの動画集 美熟女編41人VOL.02"
  })), React.createElement(Carousel.Item, null, React.createElement(_components.img, {
    src: "../../images/post/1-blowjob-loves/2/h_237nacx00104jp-6.jpg",
    alt: "唾液を垂らしてしゃぶる姿がエロすぎる！！フェラチオばかりの動画集 美熟女編41人VOL.02"
  })), React.createElement(Carousel.Item, null, React.createElement(_components.img, {
    src: "../../images/post/1-blowjob-loves/2/h_237nacx00104jp-8.jpg",
    alt: "唾液を垂らしてしゃぶる姿がエロすぎる！！フェラチオばかりの動画集 美熟女編41人VOL.02"
  }))), React.createElement("small", null, "左右の矢印のボタンをクリックすると画像がスライドします")), "\n", React.createElement(AspCartBtnBox, {
    fanzaUrl: "https://al.dmm.co.jp/?lurl=https%3A%2F%2Fwww.dmm.co.jp%2Fdigital%2Fvideoa%2F-%2Fdetail%2F%3D%2Fcid%3Dh_237nacx00104%2F&af_id=daisukipress-003&ch=toolbar&ch_id=text",
    fanza: "yes",
    duga: "yes",
    dugaPath: "ppv/planetplus-1587/",
    sokumil: "yes",
    sokumilUrl: "https://www.sokmil.com/av/_item/item392603.htm?ref=search"
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(HeadlineH4, {
    h4: "フェラ熟女 三十路編"
  }), "\n", React.createElement(_components.img, {
    src: "../../images/post/1-blowjob-loves/3.jpg",
    alt: "フェラ熟女 三十路編"
  }), "\n", React.createElement(MovieInfoTable, {
    title: "フェラ熟女 三十路編",
    actress: "真田ゆかり 翔田千里 松浦ユキ 川村あんな 増田ゆり子 ささきふう香 風間ゆみ 艶堂しほり 酒井ちなみ(紫葵) 滝澤クリスタル 安斎芳香 松島ルリ(松嶋ルリ) 加賀雅 山下紫織 川島朋子 宮川サキ 大越はるか 白鳥美鈴 桐島樹莉 藤ノ宮礼美",
    release: "2008/11/21",
    time: "121",
    manufacturer: "アカデミック",
    lavel: "電撃",
    manager: "小山美仁"
  }), "\n", React.createElement(MovieLeadText, {
    readText: "舐める！ねぶる！吸う！しゃぶる！咥える！愛情たっぷりのお掃除フェラに、たまらず連続射精する男たち！！ねっとりした熟女の舌と唇と唾液のファンタジーワールドをご賞味下さい！"
  }), "\n", React.createElement(ReviewText, {
    reviewText: "画質が悪く画面が暗いシーンが多い今作ですが、その点が逆に良い雰囲気をもたらしています。メイクの仕方や演技から時代を感じます。エロの観点からはあんまり評価しづらい作品ですが、映像作品としては中々に見ごたえのある作品です。時代の雰囲気が感じられます。普通のAV作品を見飽きてしまった方や熟女マニアの方におすすめできます。本番シーンは登場しないので消化不良感がありますので、その点は事前にご了承ください。本作の目玉であるフェラチオシーンですが、多彩なプレイ内容が登場します。ですので飽きずに楽しめました。登場する女優さんについても記載します。モデル体型の超美人といった感じではなく、一般的に外でもよく見かけるような小綺麗な美熟女やおばちゃんといった方々が登場されています。"
  }), "\n", React.createElement(Goodpoints, {
    goodpoints: React.createElement(_components.ul, null, React.createElement(_components.li, null, "アングラな雰囲気"), React.createElement(_components.li, null, "インモラルな雰囲気"), React.createElement(_components.li, null, "うざいBGMなどが無い"), React.createElement(_components.li, null, "フェラチオののプレイ内容が豊富"), React.createElement(_components.li, null, "多彩なバリエーションの熟女"), React.createElement(_components.li, null, "一般でもよく見かけるような小綺麗な熟女"))
  }), "\n", React.createElement(Badpoints, {
    badpoints: React.createElement(_components.ul, null, React.createElement(_components.li, null, "画質が悪い"), React.createElement(_components.li, null, "画面が暗い"), React.createElement(_components.li, null, "モザイクが大きい"), React.createElement(_components.li, null, "一人当たりの登場シーンが少ない"), React.createElement(_components.li, null, "本番シーンが登場しない"))
  }), "\n", React.createElement(FiveStarRating, {
    star: 3.5
  }), "\n", React.createElement(AspCartBtnBox, {
    fanza: "",
    fanzaUrl: "https://al.dmm.co.jp/?lurl=https%3A%2F%2Fwww.dmm.co.jp%2Fdigital%2Fvideoa%2F-%2Fdetail%2F%3D%2Fcid%3D148dgkd238s%2F&af_id=daisukipress-003&ch=toolbar&ch_id=text",
    duga: "yes",
    dugaPath: "ppv/academic-0074/",
    sokumil: "yes",
    sokumilUrl: "https://www.sokmil.com/av/_item/item053200.htm?ref=search"
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(HeadlineH2, {
    h2: "シリーズ ねっとりしゃぶり尽くすスケベ熟女のおフェラ"
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(HeadlineH4, {
    h4: "ねっとりしゃぶり尽くすスケベ熟女のおフェラ"
  }), "\n", React.createElement(_components.img, {
    src: "../../images/post/1-blowjob-loves/4.jpg",
    alt: "ねっとりしゃぶり尽くすスケベ熟女のおフェラ"
  }), "\n", React.createElement(MovieInfoTable, {
    title: "ねっとりしゃぶり尽くすスケベ熟女のおフェラ",
    actress: "\t真矢恭子 西城玲華 早川真美 早川なお（柊かえで） 桐岡さつき 三咲恭子",
    release: "2011/08/25",
    time: "150",
    series: "ねっとりしゃぶり尽くすスケベ熟女のおフェラ",
    manufacturer: "アロマ企画",
    lavel: "AROMA",
    manager: "夢野あいだ"
  }), "\n", React.createElement(MovieLeadText, {
    readText: "ただジュボジュボやって抜けば一丁上がり、的な往復機械フェラに飽きた方に向けて抜くシーン用のフェラではなく、チンポを少しでも長くしゃぶっていたい、三度の飯より生尺好きなスケベ熟女にチンポを与えてみました。"
  }), "\n", React.createElement(ReviewText, {
    reviewText: "今作ですが、フェラチオシーンのみを二時間半以上という長尺で若干の狂気を感じる作品となっています。モザイクが余りにも大きすぎてフェラチオを観てるのかモザイクをを鑑賞してるのか良くわからなくなります。あとたびたび男性器の接写シーンも登場するのも賛否両論ありそうです。登場する女優さんのレベルは平均高めな印象で、好みのタイプの方がきっと見つかると思います。30代～熟女といった印象で小綺麗で品の良さそうな方々が登場されます。メインのフェラチオシーンについてですが、かなり丁寧にプレイされていて中々見ごたえがありました。結論としては、熟女・フェラチオという若干絞られたテーマを扱った今作品ですが、登場する女優さんのレベルは高いですし映像作品としてのクオリティも高いです。二時間以上のフェラチオシーンをどうとらえるかによって評価が分かれそうです。割と人を選ばずにおすすめできる作品じゃないかと感じました。"
  }), "\n", React.createElement(Goodpoints, {
    goodpoints: React.createElement(_components.ul, null, React.createElement(_components.li, null, "口元の接写"), React.createElement(_components.li, null, "一人分の女優さんのフェラチオシーンの尺がかなり取られており、大ボリューム"), React.createElement(_components.li, null, "飽きが来ないように工夫されたカメラワーク"), React.createElement(_components.li, null, "じっくりねっぷり"), React.createElement(_components.li, null, "男優さんの演技の主張があんまり激しくない"))
  }), "\n", React.createElement(Badpoints, {
    badpoints: React.createElement(_components.ul, null, React.createElement(_components.li, null, "本番シーンが無い"), React.createElement(_components.li, null, "消化不良感がある"), React.createElement(_components.li, null, "あまりにもモザイクが大きすぎる"), React.createElement(_components.li, null, "画面いっぱいに展開される男性器"))
  }), "\n", React.createElement(FiveStarRating, {
    star: 3.5
  }), "\n", React.createElement("div", {
    className: "prefaceBox text-center"
  }, React.createElement("div", {
    className: "prefaceBox-title"
  }, "作品ギャラリー"), React.createElement(Carousel, {
    variant: "dark"
  }, React.createElement(Carousel.Item, null, React.createElement(_components.img, {
    src: "../../images/post/1-blowjob-loves/4/11arm00126jp-3.jpg",
    alt: "ねっとりしゃぶり尽くすスケベ熟女のおフェラ"
  })), React.createElement(Carousel.Item, null, React.createElement(_components.img, {
    src: "../../images/post/1-blowjob-loves/4/11arm00126jp-9.jpg",
    alt: "ねっとりしゃぶり尽くすスケベ熟女のおフェラ"
  })), React.createElement(Carousel.Item, null, React.createElement(_components.img, {
    src: "../../images/post/1-blowjob-loves/4/11arm00126jp-10.jpg",
    alt: "ねっとりしゃぶり尽くすスケベ熟女のおフェラ"
  })), React.createElement(Carousel.Item, null, React.createElement(_components.img, {
    src: "../../images/post/1-blowjob-loves/4/11arm00126jp-11.jpg",
    alt: "ねっとりしゃぶり尽くすスケベ熟女のおフェラ"
  }))), React.createElement("small", null, "左右の矢印のボタンをクリックすると画像がスライドします")), "\n", React.createElement(AspCartBtnBox, {
    fanza: "yes",
    fanzaUrl: "https://al.dmm.co.jp/?lurl=https%3A%2F%2Fwww.dmm.co.jp%2Fdigital%2Fvideoa%2F-%2Fdetail%2F%3D%2Fcid%3D11arm00126%2F&af_id=daisukipress-003&ch=toolbar&ch_id=text",
    duga: "yes",
    dugaPath: "ppv/aroma-0380/",
    sokumil: "yes",
    sokumilUrl: "https://www.sokmil.com/av/_item/item125184.htm?ref=search"
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(HeadlineH4, {
    h4: "ねっとりしゃぶり尽くすスケベ熟女のおフェラ2"
  }), "\n", React.createElement(_components.img, {
    src: "../../images/post/1-blowjob-loves/5.jpg",
    alt: "ねっとりしゃぶり尽くすスケベ熟女のおフェラ2"
  }), "\n", React.createElement(MovieInfoTable, {
    title: "ねっとりしゃぶり尽くすスケベ熟女のおフェラ 2",
    actress: "山岡真理 椎名舞 杉原えり 池永京子 有奈めぐみ",
    release: "\t2012年4月25日",
    time: "155",
    series: "ねっとりしゃぶり尽くすスケベ熟女のおフェラ",
    manufacturer: "アロマ企画",
    lavel: "AROMA",
    manager: "夢野あいだ"
  }), "\n", React.createElement(MovieLeadText, {
    readText: "ただジュボジュボやって抜けば一丁上がり、的な往復機械フェラに飽きた方に向けて抜くシーン用のフェラではなく、チンポを少しでも長くしゃぶっていたい、三度の飯より生尺好きなスケベ熟女にチンポを与えてみました。"
  }), "\n", React.createElement("div", {
    className: "prefaceBox text-center"
  }, React.createElement("div", {
    className: "prefaceBox-title"
  }, "作品ギャラリー"), React.createElement(Carousel, {
    variant: "dark"
  }, React.createElement(Carousel.Item, null, React.createElement(_components.img, {
    src: "../../images/post/1-blowjob-loves/5/11arm00178jp-2.jpg",
    alt: "ねっとりしゃぶり尽くすスケベ熟女のおフェラ2"
  })), React.createElement(Carousel.Item, null, React.createElement(_components.img, {
    src: "../../images/post/1-blowjob-loves/5/11arm00178jp-8.jpg",
    alt: "ねっとりしゃぶり尽くすスケベ熟女のおフェラ2"
  })), React.createElement(Carousel.Item, null, React.createElement(_components.img, {
    src: "../../images/post/1-blowjob-loves/5/11arm00178jp-11.jpg",
    alt: "ねっとりしゃぶり尽くすスケベ熟女のおフェラ2"
  })), React.createElement(Carousel.Item, null, React.createElement(_components.img, {
    src: "../../images/post/1-blowjob-loves/5/11arm00178jp-15.jpg",
    alt: "ねっとりしゃぶり尽くすスケベ熟女のおフェラ2"
  }))), React.createElement("small", null, "左右の矢印のボタンをクリックすると画像がスライドします")), "\n", React.createElement(AspCartBtnBox, {
    fanza: "yes",
    fanzaUrl: "https://al.dmm.co.jp/?lurl=https%3A%2F%2Fwww.dmm.co.jp%2Fdigital%2Fvideoa%2F-%2Fdetail%2F%3D%2Fcid%3D11arm00178%2F&af_id=daisukipress-003&ch=toolbar&ch_id=text",
    duga: "yes",
    dugaPath: "ppv/aroma-0487/",
    sokumil: "yes",
    sokumilUrl: "https://www.sokmil.com/av/_item/item131581.htm?ref=search"
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(HeadlineH4, {
    h4: "至近距離でめちゃシコ美熟女に見つめられるガン見主観フェラ むせ返る濃厚ザーメンでお口べっちょべちょ 50人8時間"
  }), "\n", React.createElement(_components.img, {
    src: "../../images/post/1-blowjob-loves/6.jpg",
    alt: "至近距離でめちゃシコ美熟女に見つめられるガン見主観フェラ むせ返る濃厚ザーメンでお口べっちょべちょ 50人8時間"
  }), "\n", React.createElement(MovieInfoTable, {
    title: "至近距離でめちゃシコ美熟女に見つめられるガン見主観フェラ むせ返る濃厚ザーメンでお口べっちょべちょ◆ 50人8時間",
    release: "\t2020/08/24",
    time: "483",
    manufacturer: "ダイナマイトエンタープライズ",
    lavel: "ダイナマイトプラチナム"
  }), "\n", React.createElement(MovieLeadText, {
    readText: "やっぱりフェラしてる時の顔が一番カワイイんですよね。最高にキレイでエロい美熟女のフェラを疑似体験！！至近距離でガン見され目と目が合いドキドキ卑猥な淫語で囁かれ興奮MAX！！じゅぼじゅぼジュルジュルいやらしい音を立てお口べっちょべちょ、キンタマからカリ首、尿道口まで丁寧におしゃぶりしてくれます！！最高の熟女AV女優だけを厳選、おち〇ぽへの愛を感じる熟女主観フェラチオの決定版50人8時間！！"
  }), "\n", React.createElement(ReviewText, {
    reviewText: "８時間を見切るのにはかなり体力を要します。あくまで目安としてですが、480÷50で一人の女優さん当たり,9分から10分ほどのフェラチオプレイ時間になるとおもいます。フェラチオシーンだけで構成されているのかと思いきや、パイズリシーンなども一部登場します。本番シーンは出てきません。出演されている女優さんの平均レベル高めです。中々見ごたえがあって楽しめました。カメラワークに中々凝った演出がされており、秀逸だと感じました。その他編集技術的な部分にも不満点はありません。編集・女優さん共にクオリティの高い作品です。8時間という大ボリュームを加味すると大変素晴らしい作品だと思います。本番シーンが登場しないという特殊性もマンネリ化の気分転換になるのではないでしょうか。"
  }), "\n", React.createElement(Goodpoints, {
    goodpoints: React.createElement(_components.ul, null, React.createElement(_components.li, null, "８時間以上超長尺"), React.createElement(_components.li, null, "口元のアップシーンが多め"), React.createElement(_components.li, null, "主観フェラチオという作品のコンセプトが秀逸。かなりエロい"), React.createElement(_components.li, null, "飽きが来ないようにカメラワークの工夫が凝らされている。"), React.createElement(_components.li, null, "８時間以上という大ボリュームのおかげで大変コストパフォーマンが高い"), React.createElement(_components.li, null, "登場する女優さんの平均レベルが高いのできっとタイプの方が見つかると思います。"), React.createElement(_components.li, null, "フェラチオのプレイ内容が豊富で飽きにくかったです。"), React.createElement(_components.li, null, "フェラチオ+αというプレイ構成"))
  }), "\n", React.createElement(Badpoints, {
    badpoints: React.createElement(_components.ul, null, React.createElement(_components.li, null, "全部鑑賞し切るのに体力が要求される"), React.createElement(_components.li, null, "本番シーンが無い"), React.createElement(_components.li, null, "たまに若めの女優さんが出演される"))
  }), "\n", React.createElement(FiveStarRating, {
    star: 4.5
  }), "\n", React.createElement("div", {
    className: "prefaceBox text-center"
  }, React.createElement("div", {
    className: "prefaceBox-title"
  }, "作品ギャラリー"), React.createElement(Carousel, {
    variant: "dark"
  }, React.createElement(Carousel.Item, null, React.createElement(_components.img, {
    src: "../../images/post/1-blowjob-loves/6/h_1386dpec00010jp-1.jpg",
    alt: "至近距離でめちゃシコ美熟女に見つめられるガン見主観フェラ むせ返る濃厚ザーメンでお口べっちょべちょ 50人8時間"
  })), React.createElement(Carousel.Item, null, React.createElement(_components.img, {
    src: "../../images/post/1-blowjob-loves/6/h_1386dpec00010jp-2.jpg",
    alt: "至近距離でめちゃシコ美熟女に見つめられるガン見主観フェラ むせ返る濃厚ザーメンでお口べっちょべちょ 50人8時間"
  })), React.createElement(Carousel.Item, null, React.createElement(_components.img, {
    src: "../../images/post/1-blowjob-loves/6/h_1386dpec00010jp-4.jpg",
    alt: "至近距離でめちゃシコ美熟女に見つめられるガン見主観フェラ むせ返る濃厚ザーメンでお口べっちょべちょ 50人8時間"
  })), React.createElement(Carousel.Item, null, React.createElement(_components.img, {
    src: "../../images/post/1-blowjob-loves/6/h_1386dpec00010jp-6.jpg",
    alt: "至近距離でめちゃシコ美熟女に見つめられるガン見主観フェラ むせ返る濃厚ザーメンでお口べっちょべちょ 50人8時間"
  }))), React.createElement("small", null, "左右の矢印のボタンをクリックすると画像がスライドします")), "\n", React.createElement(AspCartBtnBox, {
    fanza: "yes",
    fanzaUrl: "https://al.dmm.co.jp/?lurl=https%3A%2F%2Fwww.dmm.co.jp%2Fdigital%2Fvideoa%2F-%2Fdetail%2F%3D%2Fcid%3Dh_1386dpec00010%2F&af_id=daisukipress-003&ch=toolbar&ch_id=text",
    duga: "yes",
    dugaPath: "ppv/dynamiteep-0183/",
    sokumil: "yes",
    sokumilUrl: "https://www.sokmil.com/av/_item/item344734.htm?ref=search"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
